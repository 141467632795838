import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducer from './reducers'
import { createWrapper} from "next-redux-wrapper";
import { composeWithDevTools } from 'redux-devtools-extension'

const exampleInitialState = {
  tags: { items: [] },
  categories: { items: [] },
  mediaCategories: { items: [] },
  posts: { items: [] },
}

const initStore = (initialState = exampleInitialState) => {
  const middleware = [thunkMiddleware]

  let enhancer

  if (process.env.NODE_ENV === 'development') {
    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    enhancer = composeWithDevTools(applyMiddleware(...middleware))
  } else {
    enhancer = applyMiddleware(...middleware)
  }
  return createStore(reducer, initialState, enhancer)
}

export const wrapper = createWrapper(initStore, { debug: true})
