export const POSTS_REQUESTING = 'POSTS_REQUESTING'
export const POSTS_SUCCESS = 'POSTS_SUCCESS'
export const POSTS_FAILURE = 'POSTS_FAILURE'

export const POST_REQUESTING = 'POST_REQUESTING'
export const POST_SUCCESS = 'POST_SUCCESS'
export const POST_FAILURE = 'POST_FAILURE'

export const PAGE_REQUESTING = 'PAGE_REQUESTING'
export const PAGE_SUCCESS = 'PAGE_SUCCESS'
export const PAGE_FAILURE = 'PAGE_FAILURE'

export const PIRATES_REQUESTING = 'PIRATES_REQUESTING'
export const PIRATES_SUCCESS = 'PIRATES_SUCCESS'
export const PIRATES_FAILURE = 'PIRATES_FAILURE'

export const SITE_META_REQUESTING = 'SITE_META_REQUESTING'
export const SITE_META_SUCCESS = 'SITE_META_SUCCESS'
export const SITE_META_FAILURE = 'SITE_META_FAILURE'

export const SITE_TAGS_FAILURE = 'SITE_TAGS_FAILURE'
export const SITE_TAGS_SUCCESS = 'SITE_TAGS_SUCCESS'
export const SITE_TAGS_REQUESTING = 'SITE_TAGS_REQUESTING'

export const SITE_CATEGORIES_FAILURE = 'SITE_CATEGORIES_FAILURE'
export const SITE_CATEGORIES_SUCCESS = 'SITE_CATEGORIES_SUCCESS'
export const SITE_CATEGORIES_REQUESTING = 'SITE_CATEGORIES_REQUESTING'

export const SITE_MEDIA_CATEGORIES_FAILURE = 'SITE_MEDIA_CATEGORIES_FAILURE'
export const SITE_MEDIA_CATEGORIES_SUCCESS = 'SITE_MEDIA_CATEGORIES_SUCCESS'
export const SITE_MEDIA_CATEGORIES_REQUESTING =
  'SITE_MEDIA_CATEGORIES_REQUESTING'
