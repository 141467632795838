import Head from 'next/head'
import React from 'react'
import { wrapper } from '../services/store'
import { Provider } from 'react-redux'
import { StyleSheetManager } from 'styled-components'
import { shouldForwardProp } from '../utils'

const seoFallback = {
  title: 'Fount',
  metadesc: 'Digital Product Design & Development',
  'opengraph-image': 'https://www.fountstudio.com/logo_white.png',
  'twitter-title': 'Fount',
  'twitter-site': 'fount',
  'twitter-image': 'https://www.fountstudio.com/logo_white.png',
  'twitter-description': 'Digital Product Design & Development',
  'twitter-label1': 'Want to Learn More?',
  'twitter-value1': 'fountstudio.com/contact',
}

function MyApp ({ Component, ...rest }) {
  const { store, props } = wrapper.useWrappedStore(rest)
  const { pageProps } = props
  const { seo = seoFallback } = pageProps
  return (
    <React.Fragment>
      <Provider store={store}>
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta name='description' content={seo['metadesc']} />
          <meta
            property='title'
            content={seo['opengraph-title'] || seo['title']}
          />
          <title>{seo['opengraph-title'] || seo['title']}</title>
          <meta
            property='og:title'
            content={seo['opengraph-title'] || seo['title']}
          />
          <meta property='og:type' content='website' />
          <meta property='og:url' content='https://www.fountstudio.com' />
          <meta property='og:image' content={seo['opengraph-image']} />
          <meta
            property='og:description'
            content={seo['opengraph-description'] || seo['metadesc']}
          />
          <meta name='twitter:card' content={seo['metadesc']} />
          <meta name='twitter:title' content={seo['twitter-title']} />
          <meta
            name='twitter:description'
            content={seo['twitter-description']}
          />
          <meta name='twitter:label1' content={seo['twitter-label1']} />
          <meta name='twitter:value1' content={seo['twitter-value1']} />
          <meta name='twitter:site' content={seo['twitter-site']} />
          <meta name='twitter:image' content={seo['twitter-image']} />
        </Head>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <Component {...pageProps} />
        </StyleSheetManager>
      </Provider>
    </React.Fragment>
  )
}

export default MyApp
