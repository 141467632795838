import { combineReducers } from 'redux'
import uniqBy from 'lodash/uniqBy'
import {
  POSTS_FAILURE,
  POSTS_SUCCESS,
  POSTS_REQUESTING,
  POST_FAILURE,
  POST_SUCCESS,
  POST_REQUESTING,
  PAGE_FAILURE,
  PAGE_SUCCESS,
  PAGE_REQUESTING,
  SITE_TAGS_FAILURE,
  SITE_TAGS_SUCCESS,
  SITE_TAGS_REQUESTING,
  SITE_CATEGORIES_FAILURE,
  SITE_CATEGORIES_SUCCESS,
  SITE_CATEGORIES_REQUESTING,
  SITE_MEDIA_CATEGORIES_FAILURE,
  SITE_MEDIA_CATEGORIES_SUCCESS,
  SITE_MEDIA_CATEGORIES_REQUESTING,
} from './actionTypes.js'

const initialPostState = {
  isFetched: false,
  isFetching: false,
  items: [],
}

const initialSinglePostState = {
  isFetched: false,
  isFetching: false,
}

const posts = (state = initialPostState, action) => {
  switch (action.type) {
    case POSTS_REQUESTING:
      return {
        ...state,
        isFetched: false,
        isFetching: true,
      }
    case POSTS_SUCCESS:
      //console.log('GOT POSTS ', action.data)
      return {
        ...state,
        items: uniqBy([...state.items, ...action.data], 'id'),
        isFetched: true,
        isFetching: false,
      }
    case POSTS_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetched: true,
        isFetching: false,
      }
    default:
      return state
  }
}

const currentPost = (state = initialSinglePostState, action) => {
  switch (action.type) {
    case POST_REQUESTING:
      return {
        ...state,
        isFetched: false,
        isFetching: true,
      }
    case POST_SUCCESS:
      return {
        ...state,
        ...action.data[0],
        isFetched: true,
        isFetching: false,
      }
    case POST_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetched: true,
        isFetching: false,
      }
    default:
      return state
  }
}

const currentPage = (state = initialSinglePostState, action) => {
  switch (action.type) {
    case PAGE_REQUESTING:
      return {
        ...state,
        isFetched: false,
        isFetching: true,
      }
    case PAGE_SUCCESS:
      //console.log('Should have page for ' + action.data[0].id)
      return {
        ...state,
        ...action.data[0],
        isFetched: true,
        isFetching: false,
      }
    case PAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetched: true,
        isFetching: false,
      }
    default:
      return state
  }
}

const tags = (state = initialPostState, action) => {
  switch (action.type) {
    case SITE_TAGS_REQUESTING:
      return {
        ...state,
        isFetched: false,
        isFetching: true,
      }
    case SITE_TAGS_SUCCESS:
      return {
        ...state,
        items: uniqBy([...state.items, ...action.data], 'id'),
        isFetched: true,
        isFetching: false,
      }
    case SITE_TAGS_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetched: true,
        isFetching: false,
      }
    default:
      return state
  }
}

const categories = (state = initialPostState, action) => {
  switch (action.type) {
    case SITE_CATEGORIES_REQUESTING:
      return {
        ...state,
        isFetched: false,
        isFetching: true,
      }
    case SITE_CATEGORIES_SUCCESS:
      return {
        ...state,
        items: uniqBy([...state.items, ...action.data], 'id'),
        isFetched: true,
        isFetching: false,
      }
    case SITE_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetched: true,
        isFetching: false,
      }
    default:
      return state
  }
}

const mediaCategories = (state = initialPostState, action) => {
  switch (action.type) {
    case SITE_MEDIA_CATEGORIES_REQUESTING:
      return {
        ...state,
        isFetched: false,
        isFetching: true,
      }
    case SITE_MEDIA_CATEGORIES_SUCCESS:
      return {
        ...state,
        items: uniqBy([...state.items, ...action.data], 'id'),
        isFetched: true,
        isFetching: false,
      }
    case SITE_MEDIA_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetched: true,
        isFetching: false,
      }
    default:
      return state
  }
}

const appReducers = combineReducers({
  posts,
  currentPost,
  currentPage,
  tags,
  categories,
  mediaCategories,
})

const rootReducer = (state, action) => {
  /* respond to some global event
   * like a redux state reset on logout
   *
   * e.g.
   * if (action.type === 'USER_LOGOUT') {
   *   state = {
   *     i18nState: state.i18nState,
   *   }
   * }
   *
   * the above would clear app state except for the language preference
   * prior to all other reducers so they'd fall back to default states
   */

  return appReducers(state, action)
}

export default rootReducer
