import isPropValid from '@emotion/is-prop-valid'
// Method will take in an array of styles and remove them from the element with specified class name
export const removeInlineStyles = (className, styles) => {
  const e = document.getElementsByClassName(className)[0]
  styles.forEach((style) => e.style.removeProperty(style))
}

export const wait = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms)
  })
}

// http://github.com/cferdinandi/getOffsetTop
export const getOffsetTop = (elementId) => {
  let e = document.getElementById(elementId)
  var location = 0
  if (e.offsetParent) {
    do {
      location += e.offsetTop
      e = e.offsetParent
    } while (e)
  }
  return location >= 0 ? location : 0
}

// Helper to scroll to an element by their id. `paddingTop`
// allows to add some padding to where the scroll stop
export const scrollTo = (elementId, duration, paddingTop = 0) => {
  const ops = {
    '+': (a, b) => {
      return a + b
    },
    '<': (a, b) => {
      return a < b
    },
    '>': (a, b) => {
      return a > b
    },
    '-': (a, b) => {
      return a - b
    },
  }

  return new Promise((resolve) => {
    const stopScroll = (interval) => {
      clearInterval(interval)
      resolve()
    }

    const offsetTop = getOffsetTop(elementId)
    const direction =
      (window.pageYOffset || document.documentElement.scrollTop) - offsetTop > 0
        ? 'up'
        : 'down'

    let interval
    let intervalDuration = 3
    let scrollDistance = offsetTop - paddingTop
    let start = window.scrollY
    let inflection = ops[direction === 'up' ? '-' : '+'](
      (scrollDistance - start) / 4,
      start,
    )
    let t = 0
    let step = 0
    interval = setInterval(() => {
      let current = window.scrollY
      t = (intervalDuration * step) / duration
      if (ops[direction === 'up' ? '>' : '<'](current, scrollDistance)) {
        // this is a quadratic bezier curve
        let position =
          (1 - Math.pow(t, 2)) * start +
          2 * (1 - t) * t * inflection +
          Math.pow(t, 2) * scrollDistance
        window.scrollTo(0, parseInt(position))
        step += 1
      } else {
        stopScroll(interval)
      }
    }, intervalDuration)
  })
}

export const getElementHeight = (elementClass) => {
  return document.getElementsByClassName(elementClass)[0].offsetHeight
}

export const getElementWidth = (elementClass) => {
  return document.getElementsByClassName(elementClass)[0].offsetWidth
}



const rebassProps = new Set([
  'sx', // Rebass styling prop
  'variant', // Theme variant
  'tx', // Theme context
  'bg', // Background color
  'color',
  'fontSize',
  'fontWeight',
  'lineHeight',
  'letterSpacing',
  'display',
  'alignItems',
  'justifyContent',
  'flexWrap',
  'flexDirection',
  'gridGap',
  'gridColumn',
  'gridRow',
  'width',
  'height',
  'minWidth',
  'maxWidth',
  'minHeight',
  'maxHeight',
  'size',
  'position', 'top', 'left',
  'zIndex',
  'top',
  'right',
  'bottom',
  'left',
  'space', // Margin and padding
    'textAlign',
  'p', 'px', 'py', 'pt', 'pr', 'pb', 'pl', // Padding
  'm', 'mx', 'my', 'mt', 'mr', 'mb', 'ml', // Margin
]);

export const shouldForwardProp = (prop) => {
  const result = !rebassProps.has(prop) || isPropValid(prop);
  if ( ['alignItems', 'p', 'my'].includes(prop)) {

    //console.log({prop, rebass: rebassProps.has(prop), isPropValid: isPropValid(prop), result});
  }
  // return false
  return result;

}
